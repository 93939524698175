@import "@reach/dialog/styles.css";
@import "react-loading-skeleton/dist/skeleton.css";

@layer components {
  .border-mask {
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .header-unpinned .secondary-nav {
    opacity: 0;
    pointer-events: none;
    transition: none;
  }

  .rtl {
    direction: rtl;
  }
  .ltr {
    direction: ltr;
  }

  .card-image-mask-to-bottom {
    mask-image: radial-gradient(
      120% 100% at 50% 0%,
      black 99.7%,
      transparent 100%
    );
  }

  .card-image-mask-to-right-even {
    mask-image: radial-gradient(
      100% 120% at 0% 50%,
      black 99.7%,
      transparent 100%
    );
  }

  .card-image-mask-to-right-odd {
    mask-image: radial-gradient(
      100% 120% at 180% 50%,
      transparent 99.7%,
      black 100%
    );
  }

  .card-image-mask-to-bottom-even {
    mask-image: radial-gradient(
      100% 80% at 50% 157%,
      transparent 99.7%,
      black 100%
    );
  }

  .card-image-mask-to-bottom-odd {
    mask-image: radial-gradient(
      100% 80% at 50% 20%,
      black 99.7%,
      transparent 100%
    );
  }

  .filter-overlay-visible {
    @apply overflow-hidden scroll-smooth;
  }

  .article-filter-sidebar-mobile--invisible {
    @apply invisible opacity-0 pointer-events-none;
  }

  .article-filter-sidebar-mobile--visible {
    @apply visible opacity-100 pointer-events-auto;
  }

  .article-filter-sidebar {
    @apply fixed inset-0 overflow-auto transition-all delay-150 bg-white z-1;
    @apply md:static md:opacity-100 md:pointer-events-auto md:visible md:bg-transparent;
  }

  .article-filter-sidebar-content {
    @apply absolute inset-0 top-41;
    @apply overflow-auto overscroll-contain;
    @apply px-8 pb-19 sm:px-19 md:p-8 lg:p-10;
    @apply md:static md:rounded-5;
    @apply md:border md:border-black-100 md:bg-black-50;
  }

  .article-filter-sidebar-footer {
    @apply absolute bottom-0 w-full;
    @apply flex justify-center pt-5 pb-4 space-x-3;
    @apply bg-gradient-to-t from-white via-white;
    @apply md:hidden;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply m-0 appearance-none;
  }

  @supports not (backdrop-filter: blur(1px)) {
    .moz-bg-white {
      @apply bg-white;
    }
  }

  .curve-mask {
    mask-position: center;
    mask-repeat: no-repeat;
  }

  .curve-mask:first-child {
    mask-position: top;
    mask-image: url("/images/img-bg-curve_top_mb.svg");
  }

  .curve-mask:last-child {
    mask-position: bottom;
    mask-image: url("/images/img-bg-curve_bottom_mb.svg");
  }

  @screen sm {
    .curve-mask:first-child {
      mask-image: url("/images/img-bg-curve_top_tb.svg");
    }

    .curve-mask:last-child {
      mask-image: url("/images/img-bg-curve_bottom_tb.svg");
    }
  }

  @screen md {
    .curve-mask:first-child {
      mask-image: url("/images/img-bg-curve_top_sm-dt.svg");
    }

    .curve-mask:last-child {
      mask-image: url("/images/img-bg-curve_bottom_sm-dt.svg");
    }
  }

  @screen lg {
    .curve-mask:first-child {
      mask-image: url("/images/img-bg-curve_top_lg-dt.svg");
    }

    .curve-mask:last-child {
      mask-image: url("/images/img-bg-curve_bottom_lg-dt.svg");
    }
  }

  .screen-edge-spacer {
    width: calc((100vw - theme("spacing.120")) / 2);
  }

  @screen sm {
    .screen-edge-spacer {
      width: calc((100vw - theme("spacing.210")) / 2);
    }
  }
}
