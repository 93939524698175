@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* clears the ‘X’ from Internet Explorer */
  .no-native-clear-icon::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  .no-native-clear-icon::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  .no-native-clear-icon::-webkit-search-decoration,
  .no-native-clear-icon::-webkit-search-cancel-button,
  .no-native-clear-icon::-webkit-search-results-button,
  .no-native-clear-icon::-webkit-search-results-decoration {
    display: none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    height: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: theme("colors.black.primary");
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: theme("colors.black.50");
    border-radius: 10px;
    margin: 0px 15px;
  }

  .custom-scrollbar {
    scrollbar-color: theme("colors.black.primary") theme("colors.black.50");
    scrollbar-width: thin;
  }

  .border-spacing-calendar {
    border-spacing: 0.5rem 1rem;
  }
}
