@layer components {
    :root {
      --form-border-default: theme("colors.black.300");
      --form-border-hover: theme("colors.orange.primary");
      --form-border-focus: theme("colors.orange.primary");
  
      --form-border-filled-default: theme("colors.black.300");
      --form-border-filled-hover: theme("colors.black.primary");
      --form-border-filled-focus: theme("colors.black.primary");
  
      --form-label-color: theme("colors.black.300");
      --form-option-border-color: theme("colors.black.100");
      --form-supporting-text-color: theme("colors.black.400");
      --form-text-color: theme("colors.black.primary");
  
      --form-upload-button-bg-default: transparent;
      --form-upload-button-bg-hover: theme("colors.black.primary");
      --form-upload-button-border-color: theme("colors.black.primary");
      --form-upload-button-text-color-default: theme("colors.black.primary");
      --form-upload-button-text-color-hover: white;
  
      --form-upload-button-color: theme("colors.black.primary");
      --form-submit-button-text-color: white;
      --form-submit-button-bg-default: theme("colors.orange.primary");
      --form-submit-button-bg-hover: theme("colors.warm-orange.primary");
      --form-submit-button-shadow-default: theme("boxShadow.glow-6/30/40");
      --form-submit-button-shadow-hover: theme("boxShadow.glow-12/60/60");
      --form-submit-button-shadow-active: theme("boxShadow.glow-12/80/80");
    }
  
    .fsFooterForm {
      --form-border-default: theme("colors.orange.300");
      --form-border-hover: white;
      --form-border-focus: white;
  
      --form-border-filled-default: theme("colors.orange.300");
      --form-border-filled-hover: white;
      --form-border-filled-focus: white;
  
      --form-label-color: white;
      --form-option-border-color: transparent; /* border color for checkbox and radio */
      --form-supporting-text-color: white;
      --form-text-color: white;
  
      --form-upload-button-bg-default: transparent;
      --form-upload-button-bg-hover: white;
      --form-upload-button-border-color: white;
      --form-upload-button-text-color-default: white;
      --form-upload-button-text-color-hover: theme("colors.black.primary");
  
      --form-submit-button-text-color: theme("colors.black.primary");
      --form-submit-button-bg-default: white;
      --form-submit-button-bg-hover: white;
      --form-submit-button-shadow-default: theme("boxShadow.12/30/20");
      --form-submit-button-shadow-hover: theme("boxShadow.12/60/40");
      --form-submit-button-shadow-active: theme("boxShadow.12/80/60");
    }
  
    .fsSection {
      @apply space-y-6 md:space-y-8;
    }
    .fsFieldCell {
      @apply !mt-6 md:!mt-8;
    }
    @media (max-width: 601px){
      .fsSection {
        @apply !grid-cols-1;
      }
      .fsFieldCell {
        @apply !col-span-2;
      }
    }
    .fsFieldCell > .fsValidationError {
      @apply hidden;
    }
    .fsFieldCell:not(.fsAddress, .fsName, .fsFile, .fsHidden) {
      @apply relative pt-4;
      @apply transition;
      @apply h-min;
      @apply flex flex-col;
    }    
    .fsSubField,
    .fsSubFieldGroup div {
      @apply relative;
      @apply transition;
      @apply h-min;
      @apply flex flex-col;
    }
    .fsSubFieldGroup {
      @apply grid grid-cols-4 gap-y-12; 
      @apply md:gap-y-12;
      @apply md:gap-x-8;
    }
  
    .fsSubFieldGroup .fsSubField,
    .fsSubFieldGroup div {
      @apply col-span-full md:col-span-2;
    }
  
    .fsSubFieldGroup .fsFieldZip {
      @apply col-span-2 md:col-span-1;
    }
  
    .fs-form-dialog--hidden,
    .fs-clear {
      @apply hidden;
    }
    .fsForm [aria-hidden="true"] {
      @apply hidden;
    }
    .fsError {
      @apply border-2 border-red-primary;
      @apply bg-red-100;
      @apply p-4 md:p-7;
      @apply text-black-primary;
      @apply rounded-4;
      @apply my-10;
    }
    .fsSectionHeader {
      @apply flex col-span-2;
    }
  
    .fsHidden,
    .fsHiddenPage {
      @apply hidden;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active {
      @apply bg-transparent;
      @apply transition-none;
      @apply text-black-primary;
    }
  }
  