@layer components {
    .fsFile,
    .fsFieldFile {
        @apply flex flex-row flex-wrap items-center;
        @apply gap-3 pt-0;
      }
    
      .fsFile input[value="Remove File"],
      .fsFieldFile input[value="Remove File"],
      .fsHasFiles input[value="Choose File"] {
        @apply hidden;
      }
    
      .fsHasFiles input[value="Remove File"] {
        @apply block;
      }
    
      .fsFileUploadButton {
        @apply appearance-none cursor-pointer;
        @apply transition duration-150;
    
        @apply font-medium text-center;
        @apply text-15/24 md:text-18/28;
    
        @apply flex items-center justify-center space-x-3;
    
        @apply outline outline-2 outline-transparent outline-offset-1;
        @apply focus-visible:outline-blue-500;
    
        @apply border-2 rounded-3xl md:rounded-7.5;
    
        @apply border-white;
    
        @apply px-5 py-2.5 md:py-2.5;
    
        background-color: var(--form-upload-button-bg-default);
        border-color: var(--form-upload-button-border-color);
        color: var(--form-upload-button-text-color-default);
      }
    
      .fsFileUploadButton:hover {
        background-color: var(--form-upload-button-bg-hover);
        color: var(--form-upload-button-text-color-hover);
      }
    
      .fsFile .showMobile,
      .fsFieldFile .showMobile {
        @apply basis-full md:hidden;
      }
      .fsFieldCell:has(.fsFieldFile) .fsLabel span {
        @apply visible;
        @apply text-15/24 md:text-18/28;
      }
      .fsFieldCell:has(.fsFieldFile) .fsLabel + div{
        @apply mt-8 md:mt-2;
      }
      .fsFieldCell:has(.fsFile) button,
      .fsFieldCell:has(.fsFieldFile) button {
        @apply text-orange-primary underline;
      }
}