@layer components {
    
    .fsSubmit {
        @apply mt-15 md:mt-20;
      }
    .fsSubmit div {
        @apply flex justify-between;
    }
    .fsSubmit div div{
        @apply hidden;
    }
    
    .fsNextButton {
        @apply min-w-35;
        @apply appearance-none cursor-pointer;
        @apply transition duration-500;
    
        @apply font-medium text-center text-white;
        @apply text-15/24 md:text-18/28;
    
        @apply flex items-center justify-center space-x-3; 
    
        @apply outline outline-2 outline-transparent outline-offset-1;
        @apply focus-visible:outline-blue-500;
    
        @apply rounded-full;
    
        @apply bg-orange-primary hover:bg-warm-orange-primary;
        @apply shadow-glow-6/30/40 hover:shadow-glow-12/60/60 active:shadow-glow-12/80/80;
    
        @apply px-5 py-3 sm:py-3.5 md:py-4;
        @apply ml-auto;
    }
    
    .fsNextButton:hover .fsSlim {
        @apply translate-x-1;
    }
    
    .fsPreviousButton {
        @apply appearance-none cursor-pointer;
        @apply transition duration-500;
    
        @apply font-medium text-center;
        @apply text-15/24 md:text-18/28;
    
        @apply flex items-center justify-center gap-x-3 text-black-primary;
        @apply flex-row-reverse;
    }
    
    .fsPreviousButton .fsSlim {
        @apply rotate-180;
    }
    
    .fsPreviousButton:hover .fsSlim {
        @apply -translate-x-1;
    }
    
    .fsNextButton .fsSlim,
    .fsPreviousButton .fsSlim {
        @apply transition;
    }
    
    .fsNextButton .fsIcon,
    .fsPreviousButton .fsIcon {
        @apply w-5 h-5 fill-current;
    }
    
    .fsSubmitButton {
        @apply min-w-35 md:min-w-45;
        @apply appearance-none cursor-pointer;
        @apply transition duration-500;
    
        @apply font-medium text-center;
        @apply text-15/24 md:text-18/28;
    
        @apply flex items-center justify-center space-x-3;
    
        @apply outline outline-2 outline-transparent outline-offset-1;
        @apply focus-visible:outline-blue-500;
    
        @apply rounded-full md:rounded-7.5;
    
        @apply px-5 py-2.5 md:py-3.5;
    
        color: var(--form-submit-button-text-color);
        background-color: var(--form-submit-button-bg-default);
        box-shadow: var(--form-submit-button-shadow-default);
    }
    
    .fsSubmitButton:hover {
        box-shadow: var(--form-submit-button-shadow-hover);
        background-color: var(--form-submit-button-bg-hover);
    }
    
    .fsSubmitButton:active {
        box-shadow: var(--form-submit-button-shadow-active);
    }
    
}