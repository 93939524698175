$container-width: 1060px;

$primary-font: "CircularRegular";
$secondary-font: "CircularMedium";
$primary-bold-font: "CircularBold";
$primary-font-fallback: "Arial, Helvetica, sans-serif;";
$tertiary-font: "TungstenBook";
$tertiary-font-bold: "TungstenBold";
$tertiary-font-semibold: "TungstenSemiBold";
$tertiary-font-thin: "TungstenThin";
$tertiary-font-extra-thin: "TungstenXLight";

$primary-color: #f58025;
$secondary-color: #ff5504;
$secondar-color-2: #f26526;

$genesis-orange: #f58025;
$genesis-orange-50: #fef0e5;
$genesis-orange-100: #fcd9be;
$genesis-orange-200: #fac092;
$genesis-orange-300: #f8a666;
$genesis-orange-400: #f79346;
$genesis-orange-500: #f58025;
$genesis-orange-600: #f47821;
$genesis-orange-700: #f26d1b;
$genesis-orange-800: #f06316;
$genesis-orange-900: #ee500d;

$warm-orange: #f26526;
$warm-orange-50: #fdede5;
$warm-orange-100: #fbd1be;
$warm-orange-200: #f9b293;
$warm-orange-300: #f69367;
$warm-orange-400: #f47c47;
$warm-orange-500: #f26526;
$warm-orange-600: #f05d22;
$warm-orange-700: #ee531c;
$warm-orange-800: #ec4917;
$warm-orange-900: #e8370d;

$orange-100: #fde6d3;
$orange-200: #fbcca8;

$yellow: #ffcd00;
$yellow-50: #fdede5;
$yellow-100: #fff0b3;
$yellow-200: #ffe680;
$yellow-300: #ffdc4d;
$yellow-400: #ffd526;
$yellow-500: #ffcd00;
$yellow-600: #ffc800;
$yellow-700: #ffc100;
$yellow-800: #ffba00;
$yellow-900: #ffae00;

$cool-black: #464958;
$cool-black-50: #e9e9eb;
$cool-black-100: #c8c8cd;
$cool-black-200: #a3a4ac;
$cool-black-300: #7e808a;
$cool-black-400: #626471;
$cool-black-500: #464958;
$cool-black-600: #3f4250;
$cool-black-700: #373947;
$cool-black-800: #2f313d;
$cool-black-900: #20212d;

$neutral-dark: #333333;

$primary-monochrome: #464958;
$cool-black-700: #373947;
$secondary-monochrome: #a4a6ad;
$secondary-monochrome-npa: #a4a2a9;
$tertiary-monochrome: #f6f6f7;
$quartenary-monochrome: #4a4d5b;
$quinary-monochrome: #939393;
$senary-monochrome: #f4f4f4;
$septenary-monochrome: #333;
$tertiary-50: #ecedef;
$neutral-light: #f7f7f7;
$tertiary-400: #6b6d79;

$white-color: #fff;
$gray-100a: #f0f0f0 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-310: #d8d7d9 !default;
$gray-310a: #d8d8d8 !default;
$gray-400: #ced4da !default;
$gray-400a: #cac8cc !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-600a: #7b7883 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-900a: #1b1b1c !default;
$gray-900b: #eeeeee !default;
$gray-900c: #e9e9eb !default;
$black-color: #000;
$npa-tabs-text-color: #52515c !default;
$npa-plans-highlight-background-color: #f3f0ef !default;
$npa-terms-text-color: #7b7883 !default;
$npa-cart-step-line-color: #bdbbbf !default;
$npa-input-field-border: #464958 !default;
$npa-input-field-label-color: #969fa2 !default;
$npa-error-status-border: #de0000 !default;
$npa-error-status-background: #f8d0c7 !default;
$npa-plan-comparison-background: #f7f7f7 !default;
$npa-checkbox-border: #cccccc !default;
$black: $black-color;

$desktop-screen-width: 1280px;
$laptop-screen-width: 1024px !default;
$tablet-screen-width: 768px;
$mobile-screen-width: 580px;
$smallmobile-screen-width: 370px;

// 0 - 600px: xs
$npa-tablet-min-width: 601px !default;
// 601px - 1067px: sm
$npa-desktop-small-min-width: 1068px !default;
// 1068px - 1401px: md
$npa-desktop-large-min-width: 1401px !default;
// 1401px - 1680px: lg
$npa-desktop-extra-large-min-width: 1680px !default;
// 1680 - infinity: xl

$body-color: $primary-monochrome !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
) !default;

// Links
//
// Style anchor elements.

$link-color: $primary-color !default;
$link-decoration: none !default;
$link-hover-color: darken($primary-color, 15%) !default;
$link-hover-decoration: underline !default;

// Fonts

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-family-base: $primary-font !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-weight-base: 400 !default;
$line-height-base: 1.5 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: $primary-font !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$paragraph-margin-bottom: 1rem !default;

$hr-border-color: rgba(0, 0, 0, 0.1) !default;
$hr-border-width: 1px !default;
$hr-margin-y: $spacer !default;

// Forms

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($primary-color, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: 2px !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: 0 !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: transparent !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;
$input-border-color-focus: $primary-color !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten($primary-color, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(
  #{$input-height-inner-sm} + #{$input-height-border}
) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(
  #{$input-height-inner-lg} + #{$input-height-border}
) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-margin-top: 0.25rem !default;

$form-check-margin-bottom: 0.5rem !default;
$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.25rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;
