.cx-widget .cx-theme-orange {
  @apply bg-white font-sans;
}
.cx-widget.cx-theme-orange .cx-theme {
  @apply bg-white !border-dark-50 rounded-t-5;
}
.cx-widget.cx-theme-orange .cx-overlay {
  @apply bg-white;
}
 .cx-widget.cx-theme-orange .cx-svg-icon-tone1 {
  @apply fill-orange-primary;
}
.cx-widget.cx-theme-orange .cx-svg-icon-tone2 {
  @apply fill-black-primary;
}
.cx-widget.cx-theme-orange .cx-titlebar .cx-title {
  @apply !text-black-primary;
}
.cx-widget.cx-theme-orange .cx-buttons-window-control .cx-svg-icon-tone1 {
  @apply fill-black-primary;
}
.cx-widget.cx-theme-orange label {
  @apply !text-black-primary;
}
.cx-widget.cx-theme-orange .cx-icon-alert-circle {
  @apply text-red-primary;
}
.cx-widget.cx-theme-orange .cx-footer, .cx-widget.cx-theme-orange .cx-branding {
  @apply text-black-400;
}
.cx-widget.cx-theme-orange .cx-footer *, .cx-widget.cx-theme-orange .cx-branding * {
  @apply fill-black-400;
}
.cx-widget.cx-theme-orange .cx-form-control.cx-error {
  @apply border-red-primary;
}
.cx-widget.cx-theme-orange .cx-form-control::placeholder {
  @apply text-black-300;
}
.cx-widget.cx-theme-orange .cx-form-control:-moz-placeholder {
  @apply text-black-300;
}
.cx-widget.cx-theme-orange .cx-form-control::-moz-placeholder {
  @apply text-black-300;
}
.cx-widget.cx-theme-orange .cx-form-control:-ms-input-placeholder {
  @apply text-black-300;
}
.cx-widget.cx-theme-orange .cx-form-control::-webkit-input-placeholder {
  @apply text-black-300;
}
.cx-widget.cx-theme-orange input:focus, .cx-widget.cx-theme-orange textarea:focus, .cx-widget.cx-theme-orange .cx-btn:focus, .cx-widget.cx-theme-orange .cx-button-group button:focus, .cx-widget.cx-theme-orange .cx-form-control:not(.cx-error):focus {
  @apply !border-orange-primary;
}
.cx-widget.cx-theme-orange input[type="text"], .cx-widget.cx-theme-orange input[type="email"], .cx-widget.cx-theme-orange input[type="tel"], .cx-widget.cx-theme-orange input[type="number"], .cx-widget.cx-theme-orange select, .cx-widget.cx-theme-orange textarea {
  @apply bg-white text-black-primary;
}
.cx-widget.cx-theme-orange .cx-btn-default {
  @apply bg-none !border-black-primary text-black-primary rounded-5;
}
.cx-widget.cx-theme-orange .cx-btn-primary {
  @apply text-white bg-orange-500 rounded-5;
}
.cx-widget.cx-theme-orange .cx-button-group .cx-btn-primary:focus {
  @apply !border-transparent;
}
.cx-widget.cx-theme-orange .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-orange .cx-btn-default:focus {
  @apply !border-black-500;
}
.cx-widget.cx-theme-orange .cx-ac-dropdown-menu {
  @apply bg-white border-dark-50;
}
.cx-widget.cx-theme-orange .cx-smokescreen {
  @apply bg-white opacity-70;
}
.cx-widget.cx-theme-orange .cx-dialog-container .cx-dialog {
  @apply bg-white !border-dark-50;
}
.cx-widget.cx-theme-orange .cx-theme-background {
  @apply bg-white;
}
.cx-widget.cx-theme-orange .cx-theme-border {
  @apply border-2 border-solid !border-dark-50;
}
.cx-widget.cx-theme-orange .cx-theme-text {
  @apply !text-black-primary;
}
.cx-widget.cx-theme-orange .cx-loading-screen .cx-loading-icon svg g g, .cx-widget.cx-theme-orange .cx-loading-container .cx-loading-icon svg g g {
  @apply stroke-warm-orange-300;
}
.cx-widget.cx-theme-orange .cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-widget.cx-theme-orange .cx-loading-container .cx-loading-icon svg g:first-child g {
  @apply stroke-warm-orange-primary
}
.cx-wrapper > p {
  @apply mb-5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}
