@layer components {
    .fsForm input[type="text"],
    .fsForm input[type="email"],
    .fsForm input[type="tel"],
    .fsForm input[type="date"],
    .fsForm input[type="number"],
    .fsForm textarea,
    .fsForm select {
        @apply transition-all;
        @apply text-15/24 md:text-18/28;
        @apply px-1 pb-1.5; 
        @apply w-full;
        @apply bg-transparent outline-none;
        @apply placeholder:text-transparent;
        @apply text-black-primary;
        @apply border-b-2 border-black-300;
        @apply hover:!border-b-2 hover:border-orange-primary focus:border-orange-primary;
        @apply focus:!border-b-2 focus:-mb-px;
    } 
    .fsForm span label:not(.fsOptionLabel), 
    .fsForm label label:not(.fsOptionLabel) {
        @apply transition-all;
        @apply absolute left-1 -top-4;
        @apply pointer-events-auto text-12/16 md:text-13/16;
        @apply cursor-pointer;
        color: var(--form-label-color);
    }    
    .fsForm input[type="text"]:placeholder-shown,
    .fsForm input[type="email"]:placeholder-shown,
    .fsForm input[type="tel"]:placeholder-shown,
    .fsForm input[type="number"]:placeholder-shown,
    .fsForm textarea:placeholder-shown,
    .fsForm select:placeholder-shown,
    .fsFieldShortAnswer:placeholder-shown {
        @apply border-b-2;
        @apply mb-0 ;
        @apply hover:border-orange-primary focus:border-orange-primary;
    }
    .fsForm input:not(:placeholder-shown) {
        @apply border-b-2;
    }
    .fsForm input[type="text"]:placeholder-shown ~ label,
    .fsForm input[type="email"]:placeholder-shown ~ label,
    .fsForm input[type="tel"]:placeholder-shown ~ label,
    .fsForm input[type="number"]:placeholder-shown ~ label,
    .fsForm textarea:placeholder-shown ~ label,
    .fsForm select:placeholder-shown ~ label,
    .fsFieldShortAnswer:placeholder-shown ~ label {
      @apply top-0 text-15/24 md:text-18/28;
      @apply pointer-events-none;
    }    
    
    .fsForm input[type="tel"] ~ label,
    .fsForm .fsNumber ~ label {
        @apply !top-0 md:!top-0;
    }    
    .fsForm input[type="tel"]:placeholder-shown ~ label,
    .fsForm .fsNumber:placeholder-shown ~ label {
       @apply !top-4;
    }
    .fsLabel:not(span),
    .fsForm label.fsSupporting {
      @apply transition-all;
      @apply absolute top-0 left-1;
      @apply pointer-events-auto text-15/24 md:text-18/28;
      @apply cursor-pointer;
      color: var(--form-label-color);
    }
    .fsForm select {
        @apply min-h-9;
    }
    :where(.fsAddress, .fsName) .fsLabel {
      @apply text-15/24;
      color: var(--form-text-color);
    }
    .fsDateField .MuiInputAdornment-root {
        @apply hidden;
    }
    .fsFieldShortAnswer {
        @apply w-full;
    }
    .fsSubFieldGroup:has(.fsFieldEmail):not(:has(.fsFieldConfirm)) .fsSubField {
        @apply w-full col-span-4;
    }
    .fsSubFieldGroup:has(.fsFieldShortAnswer) {
        @apply grid w-full grid-cols-1;
    }
    .fsForm input[type="text"].MuiInputBase-input {
        width: 100%;
    }
    .fsForm input[type="text"].MuiInputBase-input::placeholder {
        @apply text-black-300;
    }
    .fsLabel:not(.show-label) span:not(.show-label) {
       @apply hidden; 
    }
    .fsForm textarea {
        @apply h-25 pt-2;
    }
    .fsHiddenField.fsFieldCell{
        @apply !mt-0 !pt-0 w-0 h-0;
    }

}
