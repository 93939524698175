@layer components {
    .fsFieldCell:has(.fsRadio, .fsCheckbox) {
        @apply !col-span-2;
    } 
    fieldset {
        @apply pt-4 bg-transparent;
        @apply flex flex-col justify-items-stretch;
        @apply md:grid md:grid-cols-2;
        @apply md:gap-7 lg:gap-8;
        @apply gap-y-5;
    }
    fieldset .fsOptionLabel {
        @apply bg-white cursor-pointer text-black-primary;
        @apply flex items-center gap-x-3;
        @apply rounded-3;
        @apply px-5 pb-3.5 pt-4 md:pb-4.5 md:pt-5;
        @apply transition-shadow;
        @apply text-15/24 md:text-18/28;
    
        @apply border;
        border-color: var(--form-option-border-color);
    
        @apply hover:shadow-4/14/15;
    }
    span.fieldset-label {
        @apply mb-5 text-black-primary;
        @apply static text-15/24 md:text-18/28;
    }    
    .fsLabel:has(.fieldset-label) {
        @apply top-0 relative;
    }
    .fsForm input[type="radio"] {
        @apply relative appearance-none;
        @apply flex w-5 h-5 border-2 border-orange-primary;
        @apply rounded-full;
    
        @apply focus:shadow-none;
    
        @apply after:transition-background;
        @apply after:content-[""] after:absolute after:inset-0.5 after:rounded-full;
        @apply after:bg-transparent checked:after:bg-orange-primary;
    }
    .fsCheckbox {
        @apply relative hidden;
    }
    .fsCheckbox:checked ~ .fsCheckboxIconContainer .fsCheckboxOn {
        @apply opacity-100;
    }
    .fsCheckbox .fsField {
        @apply relative hidden;
    }

    .fsCheckboxIconContainer {
        @apply relative flex w-6 h-6 text-orange-primary;
        @apply pr-6;
    }

    .fsCheckboxIcon {
        @apply absolute inset-0 fill-current;
    }

    .fsCheckboxOn {
        @apply transition-opacity;
        @apply opacity-0;
    }
    .fsField:checked ~ .fsCheckboxIconContainer .fsCheckboxOn {
        @apply opacity-100;
    }
}