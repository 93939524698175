@layer components {
  .ais-InfiniteHits-loadMore {
    @apply flex items-center space-x-3 antialiased font-medium transition duration-500;
    @apply outline outline-2 outline-transparent focus-visible:outline-blue-500 outline-offset-1;
    @apply border-2 rounded-full;

    @apply border-black-primary hover:border-dark-primary;
    @apply bg-black-primary hover:bg-dark-primary;
    @apply text-white;
    @apply shadow-6/30/20 hover:shadow-12/60/60 active:shadow-12/80/100;

    @apply px-5 py-2.5 text-15/24;
    @apply md:px-7 md:py-3.5 md:text-18/28;

    @apply disabled:hidden;
  }
}
