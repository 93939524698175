.vht-widget-container {
  @apply text-black-primary;
}

.vht-user-data-input-form-group {
  @apply flex flex-col;
}

.vht-widget-intent-step > .vht-label,
.vht-user-data-input-form-group .vht-label {
  @apply block;
  @apply pl-1;
  @apply text-12/16 md:text-13/16;
  @apply cursor-pointer;
}

select.vht-widget-input,
.vht-user-data-input-form-group .vht-widget-input {
  @apply transition-all;
  @apply text-15/24 md:text-18/28;
  @apply px-1 pb-1.5;
  @apply bg-transparent outline-none;
  @apply border-b border-transparent;
  @apply rounded-none appearance-none;

  @apply w-72;
  @apply outline-none;
  @apply border-b border-transparent;
  @apply border-b-black-300 hover:border-b-orange-primary focus:border-b-orange-primary;
  @apply focus:border-b-2 focus:-mb-px;
}

.vht-callback-form-submit {
  @apply min-w-35 md:min-w-45;
  @apply appearance-none cursor-pointer;
  @apply transition duration-500;

  @apply font-medium text-center;
  @apply text-15/24 md:text-18/28;

  @apply flex items-center justify-center space-x-3;

  @apply outline outline-2 outline-transparent outline-offset-1;
  @apply focus-visible:outline-blue-500;

  @apply rounded-full md:rounded-7.5;

  @apply px-5 py-2.5 md:py-3.5;

  @apply text-white;
  @apply bg-orange-primary;
  @apply shadow-glow-6/30/40;
}

.vht-inline-footer {
  @apply pt-5 mt-10;
  @apply flex items-center justify-center;
  @apply border-t-2 border-t-black-100;
  @apply md:justify-end;
}

.vht-widget-intent-step-choice {
  @apply mt-15 md:mt-20;
}

h1.vht-inline-header,
.vht-inline-message,
.vht-schedule-selected {
  @apply hidden;
}

.vht-schedule-region .vht-label {
  @apply text-20/24 md:text-24/28;
  @apply mb-5;
}

.vht-schedule-region > .vht-label {
  @apply hidden;
}

.day-of-week-label {
  @apply p-1;
  @apply font-normal text-16/24;
}

.vht-schedule-region-days,
.vht-schedule-region-hours,
.vht-schedule-region-times {
  @apply mb-15 md:mt-20;
}

.vht-schedule-day,
.vht-schedule-hour,
.vht-schedule-timeslot {
  @apply text-15/24 md:text-18/28;
  @apply rounded-2;
  @apply min-h-14;
  @apply w-25;
  @apply m-1;
  @apply bg-white;
  @apply tracking-wide;
  @apply cursor-pointer;
  @apply transition-all duration-150;
  @apply border border-black-50;

  @apply hover:bg-black-primary;
  @apply hover:text-white;
  @apply hover:border-black-primary;

  @apply disabled:opacity-50;
  @apply disabled:bg-black-50;
  @apply disabled:text-black-primary;
  @apply disabled:border-black-50;
}

.vht-schedule-day.selected,
.vht-schedule-hour.selected,
.vht-schedule-timeslot.selected {
  @apply text-white;
  @apply bg-orange-primary;
  @apply border-orange-primary;
}

.vht-schedule-day {
  @apply h-17;
}
