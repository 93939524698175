.slick-slider {
  @apply relative select-none touch-pan-y;
}
.slick-list {
  @apply relative overflow-x-hidden py-15;
}
.slick-list.dragging {
  @apply cursor-pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  @apply translate-x-0 translate-y-0 transform-gpu;
}
.slick-track {
  @apply relative top-0 left-0;
}
.slick-track:before,
.slick-track:after {
  @apply table;
}
.slick-track:after {
  @apply clear-both;
}
.slick-loading .slick-track,
.slick-loading .slick-slide {
  @apply invisible;
}
.slick-slide {
  @apply hidden float-left h-full;
}
[dir="rtl"] .slick-slide {
  @apply float-right;
}
.slick-slide.dragging img {
  @apply pointer-events-none;
}
.slick-initialized .slick-slide {
  @apply block;
}

/* fact-go-round variant */
.fact-go-round .swiper-slide {
  @apply sm:px-1;
}

.fact-go-round .swiper-slide [data-index] {
  transition: transform 250ms ease-in-out;
}

/* active slide */
.fact-go-round .swiper-slide-active {
  @apply z-10 px-[6%] sm:px-[3%] md:px-[2%] lg:px-[1.5%];
}

.fact-go-round .swiper-slide-active [data-index] {
  @apply origin-top cursor-auto;
  transform: scale(1.15);
}

.fact-go-round .swiper-slide-active [data-index] > div {
  @apply shadow-300;
}

.fact-go-round .swiper-slide-active [data-full-text],
.fact-go-round:hover .swiper-slide-active [data-pause] {
  @apply block;
}

.fact-go-round .swiper-slide-active [data-ellipsis-text],
.fact-go-round:hover
  .swiper-wrapper
  > div:last-of-type.swiper-slide-active
  [data-pause] {
  @apply hidden;
}

.fact-go-round .swiper-slide-active [data-fact-loader] {
  @apply w-35 sm:w-18 md:w-35;
}

/* loader */
.fact-go-round .swiper-slide [data-fact-loader] div {
  @apply w-full opacity-0;
  transform: translate3d(-100%, 0, 0);
}

@keyframes progress {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.fact-go-round .swiper-slide-active [data-fact-loader] div {
  @apply opacity-100;
  animation: 8300ms linear progress;
}

.fact-go-round:hover .swiper-slide [data-fact-loader] div {
  animation-play-state: paused;
}
