@import "styles/variables.scss";
@import "./flexgrid.scss";

#wrapper {
  background-color: $senary-monochrome;
}

#content.npa-content-wrapper {
  position: relative;
  // min-height: 100vh;
  padding-top: 76px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding-top: 101px;
  }
}

#content.npa-content-wrapper.npa-content-wrapper-no-header {
    padding-top: 0;
}

.npa-overlay-active #content.npa-content-wrapper {
  position: static;
}

.npa-content-wrapper {
  min-height: calc(100vh - 650px);

  @media (min-width: $npa-desktop-small-min-width) {
    min-height: calc(100vh - 346px);
  }
}

.npa-app-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    flex-direction: row-reverse;
    width: 1272px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.app-info-bar-spacer {
  flex: 1;
}

.app-info-bar-content {
  display: flex;
  direction: ltr;
}

.ReactModal__Body--open {
  // overflow: hidden;
  // height: 100vh;
  overflow: auto;
  height: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
