@layer components {    
    .fsBody {
        margin: -20px;
    }
    .fsSection {
        @apply max-w-full prose md:prose-md;
    }
    .fsHidden,
    .fsHiddenPage {
        @apply hidden;
    }
    .fs-form-dialog--hidden,
    .fs-clear {
        @apply hidden;
    }
    
  
    .fsSupporting:not(label) {
        @apply px-1 mt-2;
        @apply text-12/16 md:text-13/16;
        color: var(--form-supporting-text-color);
    }
    
    .fsForm div[role="alert"]:not(.fsValidationError) {
        @apply border-2 border-red-primary;
        @apply bg-red-100;
        @apply p-4 md:p-7;
        @apply text-black-primary;
        @apply rounded-4;
        @apply my-10;
    }
    .fsForm div[role="alert"]:not(.fsValidationError) a {
        @apply font-bold block;
    }
}
